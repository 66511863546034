@charset "utf-8";img, object, embed, video{max-width:100%;}
.ie6 img{width:100%;}
body{font-family:'Acronym';font-weight:normal;color:#1f1f1f;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;-webkit-overflow-scrolling:touch;padding:0;margin:0;list-style:none;background:#fff;letter-spacing:1px;}
.container{width:1170px;height:auto;margin:0 auto;padding:0;box-sizing:border-box;}
p, h1, h2, h3, h4, h5, ul{padding:0;margin:0;}
li{list-style:none;}
a{text-decoration:none;}
.clearall{clear:both;height:1px;width:auto;}
.position{position:relative;}
.dsplay{width:100%;display:table;box-sizing:border-box;}
.submit-btn{background-color:transparent;border:none;cursor:pointer;margin:0 auto;display:block;outline:0;}
.error-msg{color:red;}
.PageContent p{margin:15px 0;}
.PageContent p span{margin:15px 0;padding:0 0 0 40px;display:block;font-style:italic;}
.PageContent ol li{margin:15px 0;list-style-type:decimal;}
.PageContent ul li{padding:0 0 0 40px;}
*{box-sizing:border-box;}
.bdhding1{font-weight:700;font-size:44px;line-height:50px;color:#16556a;text-align:center;}
.bdhding2{font-weight:600;font-size:31px;line-height:38px;color:#1f1f1f;text-align:center;}
.bdfont{font-size:20px;line-height:30px;letter-spacing:0.5px;}
.bold{font-weight:700;}

/**Start Custom CSS**/
.dsktp-none{
	display: none;
}
.sm-line1 {
    margin: 0 6px 0 0px!important;
}
.sm-line2 {
    margin: 0 0 0px 6px!important;
}
.select-btntxt {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px!important;
}
.btn-txt, .btn-txt2{
	display: flex;
	justify-content: center;
	align-items: center;
}
.save_amount_badge span {
    font-size: 20px!important;
}
.footer_sec .ftrlist{
	width: 100%;
    margin-right: 0;
  	margin-bottom: 1rem;
}
.footer_sec .ftrtxt{
	margin-bottom: 1rem!important;
}
.footer_content_modal {
    padding-right: 0!important;
}
.footer_content_modal .modal-content {
    border-radius: 0;
}
.footer_content_modal .modal-header {
    border: none;
  	padding-top: 0;
    padding-bottom: 0;
}
.footer_content_modal .modal-header .close {
    font-size: 26px;
}
.footer_content_modal .modal-body p {
    margin-bottom: 1rem;
}

.footer_content_modal .modal-body .mdl_ttl {
    font-size: 28px;
    margin: 0 0 15px;
    font-weight: 600;
}
.footer_content_modal .modal-body .modal_inner_content {
    overflow-x: hidden;
    height: 353px;
    padding: 10px 10px 10px 10px;
    border: 1px solid #d2d2d2;
}
.footer_content_modal .modal_head .close {
    position: absolute;
    top: 5px;
    right: 10px;
    display: block;
    overflow: hidden;
    z-index: 99999;
}
.footer_content_modal .modal_head {
    position: relative;
    display: block;
    /* overflow: hidden; */
    width: 100%;
}
.footer_content_modal .modal_body {
    padding: 25px 25px 25px 25px;
}
.footer_content_modal .modal_body .modal_inner_content li {
    margin-bottom: 1rem;
}


/**End Custom CSS**/

@media only screen and (max-width:1179px){.container{max-width:1004px;width:100%;}
.bdhding2{font-size:27px;line-height:30px;}
.bdhding1{font-size:40px;line-height:48px;}
}
@media only screen and (max-width:1013px){.container{max-width:750px;width:100%;}
.bdhding1{font-size:35px;line-height:37px;}
.bdhding2{font-size:26px;line-height:30px;}
.bdfont{font-size:18px;line-height:26px;}
}
@media only screen and (max-width:767px){.container{width:100%;max-width:640px;}
.bdinpad{padding:0 10px;}
.bdhding1{font-size:32px;line-height:34px;}
.bdhding2{font-size:22px;line-height:28px;padding:4px 0 0 0;}
.dsktp-none{
	display: block!important;
}
.mbl_none{
	display: none!important;
}
}
@media only screen and (max-width:479px){.bdhding1{font-size:28px;line-height:30px;}
 .footer_content_modal .modal-body .mdl_ttl {
    font-size: 23px;
  }
.bdhding2{font-size:23px;line-height:27px;}
.bdfont{line-height:24px;font-size:16px;}
}
@media only screen and (max-width:359px){.bdhding2{font-size:20px;line-height:22px;}
}
.top-fix-bar{margin-bottom:80px;}
.header{height:80px;margin-top:-80px;position:relative;top:80px;background:#fff url( 'navline.jpg') left bottom repeat-x;}
.prdhdr{background:#fff url('prd-navline.jpgg') left bottom repeat-x;}
.fixed-nav .header{float:left;width:100%;position:fixed;z-index:1000;top:0;-webkit-transition:margin .3s ease-out;transition:margin .3s ease-out;margin-top:0;height:80px;}
.logo{float:left;margin:20px 0 0 0;}
a.hdrbtn, ul.nav{float:right;display:inline-block;}
a.hdrbtn{background:#ffa200;padding:0 10px;height:34px;border-radius:5px;font-size:18px;line-height:34px;color:#ffffee;font-weight:600;text-align:center;margin:23px 0 0 10px;}
ul.nav li{float:left;display:inline-block;margin:22px 0 0 0;}
ul.nav li a{background:url('top-line.png') right center no-repeat;float:left;font-size:18px;line-height:34px;text-align:center;color:#2a2a2a;padding:0 13px 0 10px;}
ul.nav li:nth-child(6) a{background:none;}
ul.nav li a.active{color:#ffa200;}
#menu-wrapper{overflow:hidden;max-width:40px;cursor:pointer;float:right;}
#menu-wrapper #hamburger-menu{position:relative;width:25px;height:20px;margin:25px 0 5px 0;}
#menu-wrapper #hamburger-menu span{opacity:1;-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";left:0;display:block;width:100%;height:3px;border-radius:10px;color:black;background-color:#636466;position:absolute;-webkit-transform:rotate(0deg);transform:rotate(0deg);-webkit-transition:.4s ease-in-out;transition:.4s ease-in-out;}
#menu-wrapper #hamburger-menu span:nth-child(1){top:0;}
#menu-wrapper #hamburger-menu span:nth-child(2){top:9px;}
#menu-wrapper #hamburger-menu span:nth-child(3){top:18px;}
#menu-wrapper #hamburger-menu.open span:nth-child(1){top:9px;-webkit-transform:rotate(135deg);transform:rotate(135deg);}
#menu-wrapper #hamburger-menu.open span:nth-child(2){opacity:0;-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";left:-60px;}
#menu-wrapper #hamburger-menu.open span:nth-child(3){top:9px;-webkit-transform:rotate(-135deg);transform:rotate(-135deg);}
#menu-container .menu-list{padding-left:0;display:block;position:absolute;width:100%;z-index:999;overflow-y:auto;overflow-x:hidden;left:-100%;top:70px;background:#16556a;border-top:1px solid #ccc;}
#menu-container .menu-list li a{font-size:18px;padding:10px 20px 10px 20px;text-align:left;color:#fff;display:block;border-bottom:1px dotted #ccc;}
#menu-container .menu-list li a.active{background:#232323;}
#menu-container .menu-list li:last-child{background:#ffa200;}
@media only screen and (max-width:1179px){ul.nav li{margin:30px 0 0 0;}
ul.nav li a{font-size:16px;line-height:20px;padding:0 10px 0 5px;}
a.hdrbtn{padding:0 12px;font-size:16px;margin:23px 0 0 5px;}
}
@media only screen and (max-width:1013px){
  .header{height:60px;top:60px;margin-top:-60px;}
.top-fix-bar{margin-bottom:60px;}
.fixed-nav .header{height:60px;}
#menu-container .menu-list{top:60px;}
ul.nav, a.hdrbtn, .prdhdrbtn{display:none;}
.logo{margin:10px 0 0 0;}
#menu-wrapper #hamburger-menu{margin:19px 0 5px 0;}
  .save_amount_badge span {
    font-size: 15px!important;
}
}
@media only screen and (max-width:479px){.logo{width:240px;margin:13px 0 0 0;}
}
/* .banner{background:url( 'banner.jpg') center top no-repeat;height:599px;padding:63px 0 0 0;overflow:hidden;} */
/*.banner{background:url( 'banner.jpg') center top no-repeat;padding:63px 0 0 0;}*/
.bannerHome{background:url( 'bannerOLD.jpg') center top no-repeat;height:599px;padding:63px 0 0 0;overflow:hidden;}
.bnrtxt-area{width:66.83%;float:left;position:relative;}
.brn-hd1{font-size:34px;line-height:40px;text-align:left;color:#fff;font-weight:700;}
.brn-hd2{font-size:56px;line-height:67px;text-align:left;color:#fff4d2;font-weight:700;}
.tiny-cupsule{display:block;margin:16px 0 0 0;}
ul.brn-list{padding:13px 0 0 4px;}
ul.brn-list li{background:url( 'brn-tk.png') left top no-repeat;font-size:21px;line-height:24px;text-align:left;color:#fff;font-weight:600;margin:12px 0 0 0;padding:0 0 0 30px;}
.brn-brn{margin:13px 0 0 0;}
.brn-pro{position:absolute;top:138px;right:-54px;}
.immune-bg{background:url( 'immune-bg.png') center top no-repeat;width:137px;height:175px;position:absolute;top:29px;right:-121px;}
.immune-bg-p1{font-size:14.80px;line-height:18px;text-align:center;color:#fff;padding:64px 0 0 0;}
.immune-bg-p1 span{font-size:23px;font-weight:700;line-height:25px;}
.brn-btm{background:#f8f8f8;padding:20px 0;border-bottom:1px solid #e9e9e9;display:table;width:100%;}
ul.brn-btm-list{margin:0 0 0 0;text-align:center;}
ul.brn-btm-list li{display:inline-block;font-size:17px;line-height:21px;text-align:left;color:#282828;font-weight:600;padding:17px 2% 17px 66px;position:relative;margin:0 2% 0 0;}
.brn-btm-icon{position:absolute;top:0;left:0;}
.brn-tm-line{position:absolute;top:0;right:0;}
ul.brn-btm-list li:last-child{margin:0 0 0 0;padding:17px 0 17px 66px;}
@media only screen and (max-width:1179px){.banner{background:url( 'banner.jpg') 60% top scroll no-repeat;}
.bnrtxt-area{width:79%;}
.brn-hd1{font-size:28px;line-height:32px;}
.brn-hd2{font-size:49px;line-height:55px;}
ul.brn-list li{font-size:20px;line-height:23px;}
.brn-pro{top:129px;right:0;}
.immune-bg{right:4px;}
ul.brn-btm-list li{font-size:16px;padding:17px 1% 17px 62px;margin:0 1% 0 0;}
ul.brn-btm-list li:last-child{margin:0 0 0 0;padding:17px 0 17px 62px;}
}
@media only screen and (max-width:1013px){.banner{background:url( 'banner-mob.jpg') center top no-repeat;background-size:cover;height:auto;padding:45px 0;}
.bnrtxt-area{width:100%;}
.brn-pro{top:129px;right:0;width:189px;}
.brn-hd2{font-size:53px;line-height:58px;}
.brn-hd1{font-size:28px;line-height:32px;}
.immune-bg{right:0px;top:89px;}
.brn-btm{padding:15px 0;}
ul.brn-btm-list li, ul.brn-btm-list li:last-child{padding:0 17px;margin:0 0 0 0;min-height:auto;float:left;text-align:center;}
.brn-btm-icon{position:inherit;top:0;left:0;right:0;display:block;margin:0 auto 7px auto;}
.brn-tm-line{position:absolute;top:14px;right:0;}
}
@media only screen and (max-width:767px){.banner{padding:20px 0 25px 0;}
  .footer_sec {
    padding: 15px 0 25px 0!important;
}
.brn-hd1{font-size:25px;line-height:30px;text-align:center;}
.brn-hd2{font-size:44px;line-height:44px;text-align:center;}
.mobbtlbox{margin:0 auto;width:280px;position:relative;display:block;}
.brn-pro{top:0;right:0;width:169px;left:0;display:block;position:inherit;margin:20px 0 0 0;}
.immune-bg{right:0px;top:0;background:url( 'immune-bg.png') center top no-repeat;width:117px;height:155px;background-size:100%;}
.immune-bg-p1{font-size:10.8px;line-height:15px;padding:58px 0 0 0;}
.immune-bg-p1 span{font-size:20px;line-height:21px;}
ul.brn-btm-list li, ul.brn-btm-list li:last-child{padding:0 12px;float:none;font-size:14px;line-height:17px;}
.brn-btm-icon{width:40px;}
}
@media only screen and (max-width:479px){.banner{padding:20px 0 25px 0;}
.brn-hd2{font-size:42px;line-height:44px;text-align:center;}
ul.brn-list li{font-size:18px;line-height:21px;padding:0 0 0 23px;background:url( 'brn-tk.png') left 5px no-repeat;background-size:16px;}
.brn-btm{padding:5px 0;}
ul.brn-btm-list{display:none;}
}
@media only screen and (max-width:359px){.brn-hd1{font-size:22px;line-height:27px;}
.brn-hd2{font-size:36px;line-height:38px;}
ul.brn-btm-list li, ul.brn-btm-list li:last-child{font-size:13px;line-height:15px;}
}
.sec1{padding:50px 0 10px 0;}
.s1-hd{color:#ff0000;}
.s1-p1{font-size:22px;line-height:28px;text-align:center;font-weight:600;color:#202020;margin:25px auto 0 auto;}
ul.s1-list1{margin:40px auto 0 auto;padding:0 100px;}
ul.s1-list1 li{float:left;margin:0 0;width:25%;padding:0 0 0 0;}
ul.s1-list1 li img{display:block;margin:0 auto;}
@media only screen and (max-width:1179px){ul.s1-list1{margin:40px auto 0 auto;padding:0 10px;}
}
@media only screen and (max-width:1013px){.sec1{padding:40px 0 10px;}
.s1-p1{font-size:20px;line-height:26px;margin:20px auto 0 auto;}
ul.s1-list1{margin:40px auto 0 auto;padding:0 0px;}
ul.s1-list1 li img{width:95%;}
}
@media only screen and (max-width:767px){.sec1{padding:35px 0 10px 0;}
.s1-p1{font-size:19px;line-height:26px;margin:14px auto 0 auto;}
ul.s1-list1 li{float:left;margin:14px 0 0 0;width:50%;padding:0 0 0 0;}
ul.s1-list1{margin:8px auto 0 auto;padding:0 0px;}
ul.s1-list1 li img{width:auto;}
}
@media only screen and (max-width:479px){ul.s1-list1 li img{width:90%;}
.s1-p1{font-size:18px;line-height:23px;margin:14px auto 0 auto;}
}
.sec2{background:url( 'sec2.png') center top no-repeat;padding:0 0 80px 0;}
.s2-logo{display:block;margin:0 auto 0 auto;padding:50px 0 0 0;}
.s2-p1{font-size:23px;line-height:28px;text-align:center;color:#16556a;font-weight:600;margin:20px auto 0 auto;}
.s2-hd1, .s2-hd2{text-align:left;}
.s2-rgt{margin:40px 0 0 0;width:57.86%;float:right;text-align:left;}
.s2-p2{font-size:26px;line-height:30px;color:#16556a;font-weight:700;margin:22px 0 0 0;}
.s2-img{position:absolute;top:228px;left:0;}
.s2-pro{position:absolute;top:401px;left:257px;}
ul.s2-list{margin:20px 0 0 0;border-radius:30px;background:#42a6b8;width:86%;height:52px;}
ul.s2-list li{float:left;background:url( 's2-sm-line.jpg') right top no-repeat;font-size:19px;line-height:22px;text-align:left;color:#fff;font-weight:700;position:relative;margin:0 0 0 0;padding:3px 26px 5px 57px;}
ul.s2-list li img{position:absolute;top:6px;left:11px;}
ul.s2-list li:last-child{background:none;padding:3px 0 5px 57px;}
@media only screen and (max-width:1179px){.sec2{padding:0 0 60px 0;}
ul.s2-list{width:91%;}
.s2-img{position:absolute;top:250px;left:0;width:347px;}
.s2-pro{position:absolute;top:378px;left:201px;}
.s2-hd1{text-align:left;}
ul.s2-list li{font-size:16px;line-height:20px;padding:7px 26px 5px 51px;}
ul.s2-list li:last-child{background:none;padding:7px 0 5px 51px;}
}
@media only screen and (max-width:1013px){.sec2{padding:0 0 40px 0;}
ul.s2-list{width:100%;}
.s2-rgt{float:none;width:100%;text-align:left;display:block;}
.s2-img{display:none;}
.s2-hd1{text-align:center;}
.s2-pro{position:inherit;top:0;left:0;right:0;display:block;margin:20px auto 0 auto;}
ul.s2-list{width:525px;margin:16px 0 0 0;}
.s2-p1{font-size:22px;line-height:26px;}
}
@media only screen and (max-width:767px){.sec2{padding:0 0 40px 0;}
ul.s2-list{width:460px;margin:16px 0 0 0;padding:0;text-align:center;}
ul.s2-list li{font-size:15px;line-height:18px;padding:8px 21px 10px 42px;}
ul.s2-list li img{top:15px;left:11px;width:25px;}
ul.s2-list li:last-child{background:none;padding:8px 0 10px 42px;}
.s2-p1{font-size:20px;line-height:25px;}
.s2-p1{font-size:20px;line-height:25px;}
}
@media only screen and (max-width:479px){ul.s2-list{width:100%;margin:16px 0 0 0;padding:0;text-align:center;border-radius:10px;height:auto;}
ul.s2-list li{font-size:14px;line-height:18px;text-align:center;padding:8px 19px 0 0;width:33.33%;background:url( 's2-sm-line.jpg') right 13px no-repeat;}
ul.s2-list li img{position:inherit;top:0;left:0;right:0;display:block;margin:0 auto 0 auto;}
ul.s2-list li:last-child{background:none;padding:8px 0 10px 0;}
.s2-p1{font-size:18px;line-height:24px;}
.s2-p2{font-size:22px;line-height:26px;}
}
@media only screen and (max-width:359px){ul.s2-list{width:100%;margin:16px 0 0 0;padding:0;text-align:center;height:78px;padding:0 8px;}
ul.s2-list li{font-size:13px;line-height:16px;text-align:center;padding:8px 0 10px 0;background:none;}
}
.sec3{padding:60px 0 0 0;}
.s3-bg1, .s3-bg2, .s3-bg3, .s3-bg4{background:url('s3-bg1.jpg') center top no-repeat;height:360px;margin:50px auto 0 auto;padding:0 0 0 0;}
.s3-bg2{background:url('s3-bg2.jpg') center top no-repeat;margin:0 auto 0 auto;}
.s3-bg3{background:url('s3-bg3.jpg') center top no-repeat;margin:0 auto 0 auto;}
.s3-bg4{background:url('s3-bg4.jpg') center top no-repeat;margin:0 auto 0 auto;}
.s3-bg1-lft, .s3-bg1-rgt{width:45%;float:left;text-align:left;padding:76px 0;}
.s3-pdd{padding:107px 0;}
.s3-bg1-rgt{float:right;}
.s3-p1{font-size:30px;line-height:34px;color:#16556a;font-weight:700;}
.s3-p2{margin:20px 0 0 0;}
.s3-icon1, .s3-icon2{position:absolute;top:138px;left:0;right:0;display:block;margin:0 auto;padding:0 0 0 0;}
.s3-icon2{left:-6px;}
@media only screen and (max-width:1179px){.s3-p1{font-size:28px;line-height:32px;}
.s3-bg1-lft, .s3-bg1-rgt{width:45%;padding:70px 0;}
}
@media only screen and (max-width:1013px){.sec3{padding:50px 0 0 0;}
.s3-p1{font-size:24px;line-height:28px;}
.s3-bg1-lft, .s3-bg1-rgt{width:45%;padding:70px 0;}
}
@media only screen and (max-width:767px){.sec3{padding:35px 0 10px 0;}
.s3-bg1, .s3-bg2, .s3-bg3, .s3-bg4{background:none;height:auto;padding:20px 0;margin:0 auto 0 auto;}
.s3-bg1{padding:30px 0 20px 0;}
.s3-bg4{padding:20px 0 30px 0;}
.s3-bg1-lft, .s3-bg1-rgt{width:100%;padding:0 0;float:none;display:block;text-align:center;height:auto;}
.s3-icon1, .s3-icon2{position:inherit;top:0;left:0;right:0;display:block;margin:-27px auto 5px auto;width:60px;}
.s3img-mob{margin:0 auto;}
.s3-p2{margin:10px 0 0 0;}
}
@media only screen and (max-width:479px){.s3-p1{font-size:22px;line-height:26px;}
}
.sec4{padding:50px 0 70px 0;overflow:hidden;background:url( 'sec4.jpg') center top no-repeat;margin:-2px 0 0 0;}
.s4-lft{width:78%;float:left;text-align:left;}
.s4-hd1, .s4-hd2{text-align:left;}
.s4-line{margin:12px 0 0 0;}
.s4-p1{margin:10px 0 0 0;width:90%;}
.s4-p2{padding:30px 0 0 0;font-weight:bold;}
.italic{font-weight:600;font-style:italic;}
.s4lftbx, .s4rgtbx{display:inline-block;float:left;width:44%;margin:20px 4% 0 0;}
.s4rgtbx{margin:20px 0 0 0;width:52%;}
.s4lable1, .s4lable2{margin:20px 0 0 0;display:block;}
.s4arrow{position:absolute;left:-70px;top:50px;}
.s4-smhd{background:url('boot-bg.png') left top no-repeat;position:relative;margin:27px 0 0 0;height:95px;padding:0 0 0 0;text-align:left;}
.s4-smhd-icn{position:absolute;top:0;left:0;}
.s4-smhd-p1{font-size:44px;line-height:42px;font-weight:700;color:#16556a;padding:0 0 0 110px; margin-bottom: 8px;}
.s4-smhd-p2{font-size:32px;line-height:20px;font-weight:600;color:#ffffff;padding:5px 0 0 114px;display:table;margin:0 0 0 0;}
.s4-bg2{background:url( 's4-bg2.png') center center no-repeat;min-height:496px;margin:0 0 0 0;}
.s4-stp{margin:0 0 0 52px;border-left:1px dotted #83c4da;}
ul.s4-list1{padding:19px 0 0 0;}
ul.s4-list1 li{position:relative;margin:18px 0 0 0;color:#1f1f1f;padding:0 0 0 46px;text-align:left;}
ul.s4-list1 li:nth-child(1){margin:0 0 0 0;}
.s4-stp1{background:#7bc0d7;border-radius:50px;height:66px;width:66px;position:absolute;top:4px;left:-35px;font-size:18px;font-weight:600;line-height:21px;color:#fff;text-align:center;padding:12px 0 0 0;}
.s4-list1-p1{font-size:24px;line-height:28px;color:#16556a;font-weight:700;}
.s4-list1-p2{margin:6px 0 0 0;width:75%;}
.s4-ord-btn{margin:22px 0 0 0;}
.btn-txt, .btn-txt2{width:364px;font-size:15px;font-weight:600;line-height:20px;text-align:center;color:#535353;margin:0 0 0 0;}
.sm-line1{margin:0 2px 4px 0;}
.sm-line2{margin:0 0 4px 2px;}
.s4-pro{position:absolute;top:153px;right:13px;}
.s4-btnprt{margin:0 0 0 15px;}
@media only screen and (max-width:1179px){.sec4{background-size:cover;}
.s4lable2{max-width:86%;}
.s4-pro{top:153px;right:0;width:241px;}
.s4-list1-p2{margin:6px 0 0 0;width:75%;}
.s4-smhd-p1{font-size:40px;line-height:41px;}
.s4-smhd-p2{font-size:29px;line-height:34px;padding:7px 0 0 112px;}
}
@media only screen and (max-width:1013px){.sec4{padding:50px 0 60px 0;background:url( 'sec4-1030.jpg') center top repeat-y;}
.s4-man{position:absolute;top:-2px;right:-234px;width:453px;}
.s4-lft{width:76%;float:left;text-align:left;}
.s4-p2{padding:20px 0 0 0;}
.s4-pro{top:155px;right:0;width:191px;}
.s4-smhd-p1{font-size:35px;line-height:39px;}
.s4-smhd-p2{font-size:28px;line-height:34px;}
.s4-btnprt{margin:0 auto 0 auto;display:block;float:none;}
.s4-ord-btn{float:none;margin:35px auto 0 auto;display:block;}
.btn-txt{margin:0 auto 0 auto;}
}
@media only screen and (max-width:767px){.sec4{padding:35px 0 40px 0;}
.s4-lft{width:100%;float:none;text-align:center;}
.s4-man{display:none;}
.s4-pro{position:inherit;top:0;right:0;left:0;display:block;margin:20px auto 0 auto;width:184px;}
.s4-p1{width:100%;}
.s4lftbx, .s4rgtbx{width:100%;margin:20px 0 0 0;}
.s4lable1, .s4lable2{margin:20px auto 0 auto;width:300px;display:block;}
.s4lable2{margin:10px auto 0 auto;width:320px;max-width:100%;}
.s4-hd1, .s4-hd2{text-align:center;}
.s4-ord-btn{margin:30px auto 0 auto;display:block;}
.btn-txt{margin:5px auto 0 auto;display:block;}
.s4-list1-p2{margin:6px 0 0 0;width:100%;}
.s4-smhd-p1{font-size:34px;line-height:37px;}
.s4-smhd-p2{font-size:26px;line-height:31px;padding:16px 0 0 115px;}
.s4-list1-p1{font-size:22px;line-height:25px;}
.s4-smhd-icn{left:6px;width:74px;}
.s4-bg2{min-height:auto;background:none;}
.s4-smhd{background:url( 'boot-bg-mob.png') left 12px no-repeat;background-size:60px;height:auto;padding:0 0 0 65px;}
.s4-smhd-p1{font-size:32px;line-height:38px;padding:0 0 0 5px;}
.s4-smhd-p2{font-size:24px;line-height:26px;padding:0 10px 5px 5px;margin:3px 0 0 0;background:#16556a;width:100%;}
.s4arrow{left:40px;top:auto;bottom:200px;width:40px;}
}
@media only screen and (max-width:479px){.s4-stp{margin:19px 0 0 27px;border-left:1px dotted #83c4da;}
.s4-stp1{height:52px;width:52px;top:4px;left:-27px;font-size:15px;line-height:16px;padding:12px 0 0 0;}
ul.s4-list1{padding:0;}
ul.s4-list1 li{padding:0 0 0 35px;}
.s4-ord-btn{margin:30px auto 0 auto;display:block;width:350px;}
.sm-line1, .sm-line2{width:30px;}
.boxfor-mob{position:relative;width:335px;margin:0 auto;display:block;}
.s4arrow{left:0;top:auto;bottom:180px;width:30px;}
.s4lable1, .s4lable2{width:270px;}
}
@media only screen and (max-width:359px){.s4-ord-btn, .s5-ord-btn{margin:30px auto 0 auto;display:block;width:100%;}
.btn-txt, .btn-txt2{width:100%;font-size:13px;line-height:16px;}
.sm-line1, .sm-line2{width:30px;}
.s4-list1-p1{font-size:20px;line-height:23px;}
.s4-smhd{background:url( 'boot-bg-mob.png') left 9px no-repeat;background-size:50px;padding:0 0 0 55px;}
.s4-smhd-p1{font-size:28px;line-height:33px;}
.s4-smhd-p2{font-size:20px;line-height:22px;padding:0 10px 3px 5px;}
.boxfor-mob{width:295px;}
.s4arrow{width:20px;}
.s4lable1, .s4lable2{width:240px;}
}
.sec5{padding:60px 0 80px 0;overflow:hidden;}
.s5-line{display:block;margin:10px auto 0 auto;}
.s5-lft, .s5-rgt{float:left;width:50%;margin:40px 0 0 0;box-sizing:border-box;padding:0 0 12px 29px;}
.s5-lft{background:url( 's5-mdline.jpg') right top repeat-y;padding:0 0 0 0;padding:0 0 10px 0;}
ul.s5-list1{margin:0 0 0 0;}
ul.s5-list1 li{position:relative;margin:30px 0 0 0;min-height:166px;padding:0 0 0 175px;text-align:left;}
ul.s5-list1 li:nth-child(1){margin:10px 0 0 0;}
ul.s5-list1 li:nth-child(4){min-height:200px;}
ul.s5-list1 li:nth-child(5){padding:15px 0 0 175px;}
.ing1{position:absolute;top:10px;left:0;}
.ing2{top:17px;}
.ing3{left:15px;}
.s5-list1-p1{font-size:24px;line-height:27px;color:#16556a;font-weight:700;}
.s5-list1-p2{font-size:20px;line-height:24px;color:#16556a;font-style:italic;font-weight:400;margin:8px 0 0 0;}
.s5-list1-p3{margin:10px 0 0 0;width:97%;}
.s5-sm-line{position:absolute;top:10px;right:-58px;}
.s5-plus{display:block;margin:-3px auto 0 auto;padding:0 0 0 22px;}
.s5-p2{font-size:22px;line-height:28px;text-align:center;color:#1f1f1f;font-weight:700;margin:-4px auto 0 auto;}
.s5lable{margin:25px auto 0 auto;display:block;}
@media only screen and (max-width:1179px){ul.s5-list1 li{min-height:200px;}
ul.s5-list1 li:nth-child(4){min-height:220px;}
.s5-list1-p1{font-size:21px;line-height:25px;}
}
@media only screen and (max-width:1013px){.sec5{padding:50px 0;}
.ing1{position:inherit;top:0;right:0;left:0;display:block;margin:0 auto 0 auto;}
ul.s5-list1 li, ul.s5-list1 li:nth-child(4), ul.s5-list1 li:nth-child(5){position:relative;margin:10px 0 0 0;min-height:290px;padding:0;text-align:center;}
ul.s5-list1 li:nth-child(5){min-height:260px;}
.s5-p2{font-size:20px;line-height:26px;}
.s5-sm-line{top:45px;}
}
@media only screen and (max-width:767px){.sec5{padding:35px 0 40px 0;}
.s5-lft, .s5-rgt{background:none;width:100%;float:none;display:block;margin:0 auto 0 auto;padding:0;}
ul.s5-list1 li, ul.s5-list1 li:nth-child(1), ul.s5-list1 li:nth-child(4), ul.s5-list1 li:nth-child(5){position:relative;margin:40px 0 0 0;min-height:auto;}
ul.s5-list1 li:nth-child(1).first{margin:30px 0 0 0;}
.s5-sm-line{display:none!important;}
.s5-plus{margin:33px auto 0 auto;padding:0 0 0 0;}
.s5-p2{font-size:18px;line-height:25px;}
.ing1{margin:0 auto 5px auto;}
}
.strip2, .strip1, .strip3{background:#e7f5fa url( 'strip2.jpg') center top no-repeat;padding:22px 0;height:320px;}
.strip1{background:#e7f5fa url( 'stp-1.jpg') center top no-repeat;}
.strip3{height:290px;}
.doc{position:absolute;top:-60px;left:-30px;}
.doc-seal{position:absolute;top:19px;left:206px;}
.stp2-rgt{float:right;margin:0 0 0 0;width:66%;text-align:left;}
.stp2-p1{font-size:40px;line-height:44px;color:#16556a;font-weight:700;}
.stp2-p2{margin:10px 0 0 0;}
.stp2-pro{position:absolute;top:-32px;left:0;}
.stp1-left{float:left;margin:0 0 0 0;width:69%;text-align:left;}
.stp-img1{position:absolute;top:-58px;right:10px;}
@media only screen and (max-width:1179px){.strip1, .strip3{background-size:cover;}
.doc{top:-18px;width:300px;}
.stp-img1{top:-20px;width:330px;}
.doc-seal{top:29px;left:195px;width:131px;}
.stp2-pro{position:absolute;top:17px;left:0;width:329px;}
}
@media only screen and (max-width:1013px){.strip1, .strip2{background:url( 'stp-1-tab.jpg') center top no-repeat;background-size:cover;height:auto;padding:25px 0 30px 0;}
.stp2-rgt, .stp1-left{float:none;margin:0 0 0 0;width:100%;text-align:center;}
.doc, .stp-img1{display:none;}
.doc-seal{position:inherit;left:0;top:0;right:0;display:block;margin:0 auto 10px auto;}
.btn-txt2{margin:0 auto 0 auto;display:block;}
.stp2-p1{font-size:35px;line-height:42px;}
.stp2-pro{position:inherit;top:0;left:0;right:0;display:block;margin:0 auto 0 auto;}
}
@media only screen and (max-width:479px){.stp2-p1{font-size:30px;line-height:34px;}
.s5-ord-btn{margin:20px auto 0 auto;display:block;width:350px;}
.stp2-pro{width:200px;}
}
@media only screen and (max-width:359px){.stp2-p1{font-size:28px;line-height:28px;}
}
.sec6{padding:60px 0 80px 0;overflow:hidden;}
ul.s6-lsit1{margin:55px 0 0 0;}
ul.s6-lsit1 li{background:url( 's6-line1.jpg') right 90px no-repeat;float:left;padding:0 1%;text-align:center;width:33.33%;margin:0 0;box-sizing:border-box;}
ul.s6-lsit1 li:last-child{background:none;}
ul.s6-lsit1 li img{display:block;margin:0 auto;}
.s6-lsit1-p1{font-size:21px;line-height:27px;color:#16556a;font-weight:700;margin:10px auto 0 auto;}
.s6-lsit1-p2{margin:10px auto 0 auto;}
.s6-prt2{margin:50px auto 0 auto;position:relative;}
.s6btn-prt{width:364px;display:block;position:absolute;top:0;right:0;left:0;margin:0 auto;}
ul.s6-list2, ul.s6-list3{float:left;width:30%;margin:10px 0 0 0;}
ul.s6-list3{float:right;}
ul.s6-list2 li, ul.s6-list3 li{float:left;width:50%;background:url( 's6-line2.jpg') right 30px no-repeat;font-size:19px;line-height:22px;text-align:center;padding:0 2%;color:#000000;font-weight:700;}
ul.s6-list2 li:last-child, ul.s6-list3 li:last-child{background:none;}
ul.s6-list2 li img, ul.s6-list3 li img{display:block;margin:0 auto 6px auto;}
@media only screen and (max-width:1179px){.s6-lsit1-p1{margin:10px 40px 0 40px;}
}
@media only screen and (max-width:1013px){.sec6{padding:50px 0 60px 0;}
ul.s6-lsit1 li{background:url( 's6-line1.jpg') right 120px no-repeat;padding:0 1%;}
.s6-lsit1-p1{font-size:20px;line-height:24px;margin:10px 0 0 0;}
.s6-prt2{margin:36px auto 0 auto;}
.s6btn-prt{position:inherit;left:0;right:0;display:block;top:0;margin:30px auto 0 auto;}
ul.s6-list2, ul.s6-list3{width:50%;}
ul.s6-list2 li, ul.s6-list3 li{width:50%;font-size:18px;line-height:22px;padding:0 5%;}
ul.s6-list2 li:last-child{background:url( 's6-line2.jpg') right 30px no-repeat;}
}
@media only screen and (max-width:767px){.sec6{padding:35px 0 40px 0;}
.slid{text-align:center;display:block;margin:40px auto 0 auto;}
ul.s6-lsit1{margin:0 0 0 0;}
ul.s6-lsit1 li{background:none;width:100%;float:none;display:block;margin:40px auto 0 auto;}
ul.s6-lsit1 li:first-child{margin:30px auto 0 auto;}
.s6-lsit1-p1{min-height:auto;}
ul.s6-list2, ul.s6-list3{width:100%;float:none;display:block;}
ul.s6-list2 li, ul.s6-list3 li{width:50%;font-size:16px;line-height:20px;padding:0 1%;margin:20px 0 0 0;}
ul.s6-list2 li:last-child{background:none;}
.s6btn-prt{margin:50px auto 0 auto;}
.s6-prt2{margin:16px auto 0 auto;}
}
@media only screen and (max-width:479px){.s6btn-prt{width:auto;margin:30px auto 0 auto;}
}
.sec7{padding:60px 0 60px 0;background:#e7f5fa;}
.chart{margin:176px 0 0 0;display:table;width:100%;}
.crt-bx1, .crt-bx2, .crt-bx3, .crt-bx4, .crt-bx5{float:left;margin:0 0 0 0;display:inline-block;}
.crt-bx1{background:#fff;border:1px solid #d1d3d4;border-right:none;border-radius:4px;min-height:391px;margin:100px 0 0 0;width:23.33%;}
ul.crt-bx1-list{margin:0 0 0 0;}
ul.crt-bx1-list li{margin:0 0 0 0;font-size:20px;line-height:23px;min-height:65px;text-align:left;color:#3e3e3e;font-weight:700;padding:20px 0 20px 15px;border-bottom:2px solid #e6e6e6;box-sizing:border-box;}
.crt-bx2{width:18%;border-radius:4px;border-bottom:1px solid #fff;position:relative;background:#16556a;text-align:center;margin:0 2px 0 0;}
.s7-arw{position:absolute;left:0;right:0;bottom:-27px;display:block;margin:0 auto;}
.chrt2-hd1{font-size:30px;line-height:33px;text-align:center;color:#ffffff;font-weight:700;min-height:227px;}
.chrt-pro1{display:block;margin:-128px auto 0 auto;}
ul.crt-bx2-list{margin:0 0 0 0;}
ul.crt-bx2-list li{margin:0 0 0 0;font-size:20px;line-height:23px;font-weight:bold;min-height:62px;color:#fff;padding:20px 0 20px 0;box-sizing:border-box;}
ul.crt-bx2-list li:nth-child(1), ul.crt-bx2-list li:nth-child(3), ul.crt-bx2-list li:nth-child(5){background:#073953;border-bottom:2px solid #246479;border-top:2px solid #246479;}
ul.crt-bx2-list li:nth-child(6){border-bottom:2px solid #246479;}
ul.crt-bx2-list li.lastbx{border-bottom:1px solid #d4e1ec;-webkit-box-shadow:0 9px 7px -6px #a1b3bb;-moz-box-shadow:0 9px 7px -6px #a1b3bb;box-shadow:0 9px 7px -6px #a1b3bb;}
.crt-bx3, .crt-bx4, .crt-bx5{width:19.18%;border:2px solid #d0e1ec;background:#edfaff;text-align:center;border-radius:4px;margin:0 1px 0 0;float:left;}
.chrt3-hd1, .chrt4-hd1, .chrt5-hd1{font-size:22px;line-height:24px;text-align:center;color:#636363;font-weight:600;min-height:203px;}
.chrt4-hd1{min-height:207px;}
.chrt5-hd1{min-height:212px;}
.chrt-pro2, .chrt-pro3, .chrt-pro4{display:block;margin:-104px auto 5px auto;}
.chrt-pro3{margin:-108px auto 5px auto;}
.chrt-pro4{margin:-113px auto 5px auto;}
ul.crt-bx3-list{margin:0 0 0 0;}
ul.crt-bx3-list li{margin:0 0 0 0;font-size:20px;line-height:23px;min-height:62px;color:#636363;padding:19.72px 0 19.72px 0;box-sizing:border-box;}
ul.crt-bx3-list li:nth-child(1), ul.crt-bx3-list li:nth-child(3), ul.crt-bx3-list li:nth-child(5),
ul.crt-bx3-list li:nth-child(7){background:#dfeef3;border-bottom:2px solid #dee4e6;border-top:2px solid #dee4e6;}
.s7-btn-pz{margin:21px 0 0 209px;}
ul.crt-bx3-list li:last-child{min-height:0;}
@media only screen and (max-width:1179px){.s7-btn-pz{margin:21px 0 0 149px;}
.chrt2-hd1{font-size:20px;line-height:30px;}
ul.crt-bx1-list li{font-size:19px;line-height:23px;padding:20px 0 20px 10px;}
}
@media only screen and (max-width:1013px){.sec7{padding:50px 0;}
.chart{margin:116px 0 0 0;}
.chrt3-hd1, .chrt4-hd1, .chrt5-hd1{font-size:18px;line-height:20px;}
ul.crt-bx1-list li{padding:20px 0 20px 7px;}
ul.crt-bx1-list li:nth-child(2), ul.crt-bx1-list li:nth-child(5),
ul.crt-bx1-list li:nth-child(3), ul.crt-bx1-list li:nth-child(4){padding:7px 0 10px 7px;}
ul.crt-bx1-list li:nth-child(3), ul.crt-bx1-list li:nth-child(4){padding:18px 0 12px 7px;}
ul.crt-bx1-list li, ul.crt-bx2-list li, ul.crt-bx3-list li{font-size:18px;}
.s7-btn-pz{float:left;margin:5px 0 0 70px;}
}
@media only screen and (max-width:767px){.sec7{padding:35px 0 40px 0;}
.crt-bx1{min-height:372px;}
ul.crt-bx1-list li{font-size:16px;line-height:18px;min-height:62px;padding:20px 0 20px 5px;}
.chrt-pro1{display:block;margin:-84px auto 0 auto;width:80px;}
.chrt2-hd1{font-size:16px;line-height:28px;min-height:172px;}
ul.crt-bx2-list li{font-size:16px;line-height:20px;min-height:60px;padding:20px 0 20px 0;}
ul.crt-bx1-list li, ul.crt-bx2-list li, ul.crt-bx3-list li{font-size:16px;}
.chrt3-hd1, .chrt4-hd1, .chrt5-hd1{font-size:15px;line-height:17px;}
.chrt-pro2{margin:-85px auto 5px auto;width:64px;}
.chrt-pro3{margin:-85px auto 5px auto;width:60px;}
.chrt-pro4{margin:-67px auto 5px auto;width:64px;}
.crt-bx1{margin:89px 0 0 0;}
.chrt3-hd1{min-height:172px;}
.chrt4-hd1{min-height:172px;}
.chrt5-hd1{min-height:155px;}
ul.crt-bx3-list li{margin:0 0 0 0;font-size:16px;line-height:18px;min-height:62px;padding:21px 0 20px 0;box-sizing:border-box;}
.crt-bx3, .crt-bx4, .crt-bx5{width:19.10%;}
ul.crt-bx1-list li:nth-child(2), ul.crt-bx1-list li:nth-child(3), ul.crt-bx1-list li:nth-child(4),
ul.crt-bx1-list li:nth-child(5){padding:11px 0 10px 7px;}
ul.crt-bx2-list li.lastbx, .s7-arw{min-height:30px;padding:0;display:none;}
}
@media only screen and (max-width:685px){.chrt3-hd1, .chrt4-hd1, .chrt5-hd1{font-size:12px;line-height:14px;}
.chrt2-hd1{font-size:13px;line-height:26px;min-height:172px;}
ul.crt-bx1-list li, ul.crt-bx2-list li, ul.crt-bx3-list li{font-size:14px;}
}
@media only screen and (max-width:479px){.chart{width:338px;display:table;margin:100px auto 0 auto;}
.crt-bx5, .crt-bx4{display:none;}
.crt-bx1, .crt-bx2, .crt-bx3, .crt-bx4{width:33%;float:left;}
}
@media only screen and (max-width:359px){.chart{width:298px;}
ul.crt-bx1-list li, ul.crt-bx2-list li, ul.crt-bx3-list li{font-size:13px;}
}
.sec8{padding:60px 0 60px 0;overflow:hidden;}
.testi-sec{float:left;width:100%;padding:0 80px;margin-top:30px;}
.testi-box{float:left;width:100%;position:relative;text-align:left;color:#2f2f2f;padding-left:110px;border-bottom:1px solid #e4e4e5;padding-bottom:30px;margin-bottom:30px;}
.testi-icon{width:78px;height:78px;border-radius:50%;background:#a6c0c9;color:#fff;font-size:30px;line-height:78px;position:absolute;text-align:center;left:0;}
.testi-box h3{font-size:24px;line-height:26px;color:#2f2f2f;font-weight:700;margin-bottom:10px;}
.t-star{margin:20px 0 0 0;}
.testi-abt{margin-top:10px;}
.testi-name{font-size:22px;line-height:26px;color:#5d5d5d;font-weight:700;margin-top:15px;}
.testi-name span{font-size:18px;font-weight:600;color:#5d5d5d;}
.testi-sec .testi-box:last-child{border-bottom:none;margin-bottom:0;padding-bottom:0;}
@media only screen and (max-width:1179px){.testi-sec{padding:0 0px;}
}
@media only screen and (max-width:1013px){.sec8{padding:50px 0;}
.testi-box h3{font-size:22px;line-height:24px;}
}
@media only screen and (max-width:767px){.sec8{padding:35px 0 40px 0;}
.testi-box{padding-left:65px;padding-bottom:20px;margin-bottom:20px;}
.testi-icon{width:52px;height:52px;font-size:20px;line-height:52px;}
.testi-box h3{font-size:20px;line-height:22px;}
.testi-name{font-size:19px;line-height:22px;margin-top:10px;}
.testi-name span{font-size:16px;}
}
.sec9{padding:60px 0 60px 0;overflow:hidden;}
.accord-container{width:86%;margin:0 auto;display:table;}
#accordion{height:auto!important;margin:44px auto 0 auto;display:block;width:100%;}
.ui-accordion .ui-accordion-header{display:block;cursor:pointer;zoom:1;text-align:left;padding:14px 34px 15px 58px;outline:none;position:relative;font-size:24px;line-height:28px;font-weight:700;color:#16556a;text-align:left;border:1px solid #ece9d9;background:url( 'close.png') 98% 25px no-repeat;margin:10px auto 0 auto;}
.ui-accordion .ui-accordion-header.nobdr{border-bottom:none;}
.ui-accordion .ui-accordion-header.active{-moz-box-shadow:none;-webkit-box-shadow:none;box-shadow:none;background:url( 'open.png') 98% 25px no-repeat;border-bottom:none;}
.ui-accordion .ui-accordion-header .bullet{position:absolute;left:15px;top:13px;background:url( 'printed.png') left top no-repeat;height:29px;width:29px;}
.ui-accordion .ui-accordion-header.active .bullet{background:url( 'printed.png') left top no-repeat;height:29px;width:29px;}
.ui-accordion .ui-accordion-content{padding:0;border-top:0;overflow:auto;zoom:1;border:none;padding:0 0 10px 0;background:#fff;border:1px solid #ece9d9;border-top:none;}
.accord-txt{box-sizing:border-box;float:left;text-align:left;padding:5px 43px 10px 60px;width:100%;}
@media only screen and (max-width:1179px){.accord-container{width:100%;}
}
@media only screen and (max-width:1013px){.sec9{padding:50px 0;}
#accordion{margin:30px auto 0 auto;}
}
@media only screen and (max-width:767px){.sec9{padding:35px 0 40px 0;}
#accordion{margin:20px auto 0 auto;}
.ui-accordion .ui-accordion-header{font-size:21px;line-height:28px;padding:14px 38px 14px 50px;}
.accord-txt{padding:5px 20px 10px 20px;}
.ui-accordion .ui-accordion-header .bullet{left:9px;top:15px;}
}
@media only screen and (max-width:479px){.ui-accordion .ui-accordion-header{font-size:20px;line-height:24px;padding:11px 35px 11px 45px;}
.accord-txt{padding:5px 10px 5px 10px;}
}
@media only screen and (max-width:359px){.ui-accordion .ui-accordion-header{font-size:18px;line-height:22px;padding:11px 25px 11px 45px;}
}

.sprite {
    background-image: url( 'spritesheet.png');
    background-repeat: no-repeat;
    display: block;
}

.sprite-s2-logo {
    width: 121px;
    height: 48px;
    background-position: -5px -5px;
    margin-top: 50px;
}

.sprite-s3-icon2 {
    width: 81px;
    height: 81px;
    background-position: -141px -5px;
    margin: auto;
    top: 138px;
    position: absolute;
    left: 0;
    right: 0;
}

.sprite-s4-line {
    width: 387px;
    height: 19px;
    background-position: -5px -96px;
}

.sprite-s5-sm-line {
    width: 126px;
    height: 11px;
    background-position: -227px -5px;
}

.sprite-sm-line {
    width: 49px;
    height: 1px;
    background-position: -227px -26px;
}

.sprite-star-rvw2 {
    width: 118px;
    height: 44px;
    background-position: -227px -37px;
    margin: 20px auto auto;
}
.testi-abt2 {
    margin-bottom: 30px;
}
@media only screen and (max-width: 979px){
  .footer_sec .ftrlist {
    width: 100%;
	}
}
@media only screen and (max-width: 639px){
.footer_sec .ftrlist {
    width: 100%;
	}
  
}

@media only screen and (max-width: 479px){
.footer_sec .ftrlist {
    margin: 0;
	}
  
}

@media only screen and (max-width: 359px){
.select-btntxt {
    font-size: 11px!important;
}
}
